import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    mode: "dark",
  },
  shape: {
    borderRadius: 10,
  },
});
